import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import propTypes from 'prop-types';
import { Navigation, FreeMode } from 'swiper/modules';
import { useRecoilValue } from 'recoil';
import { locationTypes } from '../../../../state/filter';
import LocationTypeButton from './LocationTypeButton';
import ArrowButton from '../../buttons/ArrowButton';
import { favoritesLocationIds } from '../../../../state/location';

import 'swiper/css';
import 'swiper/css/pagination';

const BottomHeader = ({
  isMinimized,
  activeType,
  isVisible,
  setLocationType,
  isFavorites,
  setFavorites,
}) => {
  const [pageState, setPageState] = useState(1);
  const allLocationTypes = useRecoilValue(locationTypes);
  const favorites = useRecoilValue(favoritesLocationIds);

  return (
    <section
      className={`flex flex-col relative
       transition-all group/bottomheader
       ${!isVisible ? 'max-h-0 overflow-hidden' : 'h-[50px] max-h-[50px] md:h-[70px] md:max-h-[70px]'} `}
      aria-label="Filtrera på typ av plats"
      role="group"
    >
      <div className="md:px-16 flex flex-col relative h-full justify-end md:justify-center">
        <div
          className={`hidden md:flex space-x-4 top-0 absolute right-0 w-full justify-between
            top-1/2 transform -translate-y-1/2 px-4
            transition-all duration-500 opacity-0 group-hover/bottomheader:opacity-100`}
          aria-hidden="true"
        >
          <ArrowButton
            className={`custom-prev ${pageState === 1 ? 'opacity-0' : 'opacity-100'}`}
            orientation="-rotate-90"
            tabIndex={-1}
            isAriaHidden="true"
          />
          <ArrowButton
            className={`custom-next ${pageState === -1 ? 'opacity-0' : 'opacity-100'}`}
            orientation="rotate-90"
            tabIndex={-1}
            isAriaHidden="true"
          />
        </div>
        <Swiper
          onSlideChange={(e) =>
            setPageState(e.isBeginning ? 1 : e.isEnd ? -1 : 0)
          }
          slidesPerView="auto"
          spaceBetween={0}
          modules={[Navigation, FreeMode]}
          freeMode={true}
          speed={600}
          className="mySwiper h-full w-full justify-center pr-4 md:pr-0 relative"
          navigation={{
            nextEl: '.custom-next',
            prevEl: '.custom-prev',
          }}
          slidesPerGroup={5}
          breakpoints={{
            640: {
              spaceBetween: 100,
            },
          }}
        >
          <SwiperSlide
            style={{
              width: 'auto',
            }}
          >
            <LocationTypeButton
              label="Visa alla"
              onClick={() => setLocationType(undefined)}
              name="all"
              isActive={!activeType && !isFavorites}
              isVisible={isMinimized}
              tabIndex={isVisible ? 0 : -1}
            />
          </SwiperSlide>
          {favorites.length > 0 && (
            <SwiperSlide
              style={{
                width: 'auto',
              }}
            >
              <LocationTypeButton
                label="Mina favoriter"
                onClick={() => setFavorites(favorites)}
                name="favorites"
                isActive={isFavorites}
                isVisible={isMinimized}
                tabIndex={isVisible ? 0 : -1}
                aria-hidden={!isFavorites}
                aria-label="Mina favoriter"
              />
            </SwiperSlide>
          )}
          {allLocationTypes.map((type, idx) => (
            <SwiperSlide
              key={idx}
              style={{
                width: 'auto',
              }}
            >
              <LocationTypeButton
                label={type.label}
                onClick={() => setLocationType(type.id)}
                name={type.name}
                isDisabled={!type.is_available}
                isActive={activeType === type.id}
                tabIndex={isVisible ? 0 : -1}
              />
            </SwiperSlide>
          ))}
          <div
            className={`h-full w-4 absolute left-0 top-0 bg-gradient-to-l from-transparent to-secondary z-10
          ${pageState !== 1 ? 'visible' : 'invisible'}`}
          />
          <div
            className={`h-full w-4 absolute right-0 top-0 bg-gradient-to-r from-transparent to-secondary z-10
          ${pageState !== -1 ? 'visible' : 'invisible'}`}
          />
        </Swiper>
      </div>
    </section>
  );
};

BottomHeader.propTypes = {
  isMinimized: propTypes.bool,
  setLocationType: propTypes.func,
  setLocationIds: propTypes.func,
  isFavorites: propTypes.bool,
  setFavorites: propTypes.func,
};

export default BottomHeader;

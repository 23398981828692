import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SkeletonBox from '../../generic/loading/SkeletonBox';

const LocationDetailsGalleryImageButton = ({
  url,
  alt,
  onClick,
  style,
  height = 'h-full',
  width = 'w-full',
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
  };

  return (
    <button
      className={`${style} ${width} ${height} hover:opacity-90 hover:scale-[1.02] transition-all ease-in-out`}
      onClick={onClick}
      aria-hidden="true"
      tabIndex="-1"
      role="presentation"
    >
      {isLoading && <SkeletonBox className="size-full rounded-2xl" />}
      <img
        src={url}
        alt={alt}
        className={`size-full object-cover rounded-2xl animate-fadeIn ${isLoading ? 'hidden' : ''}`}
        onLoad={handleImageLoad}
        onError={handleImageError}
      />
    </button>
  );
};

LocationDetailsGalleryImageButton.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default LocationDetailsGalleryImageButton;

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import getIcon from '../../util/getIcon';
import icon from '../../config/icons';

const LocationDetailsPlaceDescription = ({ title, description }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const descriptionRef = useRef(null);
  const MAX_HEIGHT = 150;

  useEffect(() => {
    const checkOverflow = () => {
      if (descriptionRef.current) {
        if (descriptionRef.current.scrollHeight > MAX_HEIGHT) {
          setIsOverflowing(true);
        }
      }
    };

    requestAnimationFrame(checkOverflow);
  }, [description]);

  return (
    <div className="flex flex-col items-start w-full">
      <h3 className="text-2xl font-medium mb-6">{title}</h3>
      <div
        className="overflow-hidden transition-all"
        ref={descriptionRef}
        style={{ maxHeight: isExpanded ? 'none' : `${MAX_HEIGHT}px` }}
      >
        <p className="text-text2">{description}</p>
      </div>
      {isOverflowing && (
        <button
          className="flex items-center mt-2 cursor-pointer gap-2"
          onClick={() => setIsExpanded(!isExpanded)}
          aria-expanded={isExpanded}
          aria-hidden={true}
        >
          {isExpanded ? 'Läs mindre' : 'Läs mer'}
          {getIcon(icon.ARROW, `${isExpanded ? '' : 'rotate-180'}`)}
        </button>
      )}
    </div>
  );
};

LocationDetailsPlaceDescription.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default LocationDetailsPlaceDescription;

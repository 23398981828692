const icon = {
  SEARCH: 'Sök',
  FILTER: 'Filter',
  ARROW: 'Pil',
  ARROW_OUTWARD: 'Pil extern',
  MENU: 'Meny',
  CLOSE: 'Stäng',
  CHECKED: 'Checkad',
  UNCHECKED: 'Inte checkad',
  HALF_CHECKED: 'Halvt checkad',
  PRINT: 'Skriv ut',
  SHARE: 'Dela',
  COPY: 'Kopiera',
  EMAIL: 'Maila',
  FACEBOOK: 'Facebook',
  CHECK: 'Checka',
  TWITTER: 'Twitter',
  WHATSAPP: 'Whatsapp',
  MESSENGER: 'Messenger',
  HEART: 'Hjärta',
  HEART_FILLED: 'Fullt hjärta',
  PIN: 'Pin',
  MAP: 'Karta',
  IMAGE: 'Bild',
  _360: '360',
  DIRECTIONS: 'Vägbeskrivning',
  PLUS: 'Plus',
  MINUS: 'Minus',
  NAVIGATION: 'Navigering',
  NAVIGATION_FILLED: 'Navigering fylld',
  FLAG: 'Flagga',
  STAR: 'Stjärna',
  INFO: 'Information',
};

export default icon;

import React from 'react';
import PropTypes from 'prop-types';
import getIcon from '../../../../../util/getIcon';
import icon from '../../../../../config/icons';

const FilterTypePickerButton = ({
  label,
  icon: filterIcon,
  isActive,
  isDisabled,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const handleClick = () => {
    if (!isDisabled) {
      onClick();
    }
  };

  return (
    <button
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      aria-label={label}
      aria-pressed={isActive}
      aria-hidden={isDisabled}
      className={`
        ${isDisabled ? 'opacity-30' : ''}
        ${isActive ? 'border-primary' : 'border-transparent hover:bg-hover'}
        flex flex-col justify-between items-center text-center px-2 py-4
        rounded-lg text-text1 border-2 relative
        size-full`}
      onClick={handleClick}
    >
      {isActive && getIcon(icon.CHECK, 'absolute top-1 right-2 text-3xl')}
      {filterIcon}
      <span className="text-xs font-semibold">{label}</span>
    </button>
  );
};

FilterTypePickerButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  isActive: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default FilterTypePickerButton;

import React from 'react';
import propTypes from 'prop-types';
import getIcon from '../../../util/getIcon';
import icon from '../../../config/icons';

const ArrowButton = ({
  onClick,
  isDisabled,
  className,
  orientation,
  tabIndex = 0,
  ariaLabel,
  isAriaHidden,
}) => {
  return (
    <button
      aria-label={ariaLabel}
      aria-hidden={isAriaHidden}
      tabIndex={tabIndex}
      disabled={isDisabled}
      className={`flex items-center justify-center
      p-2 shadow rounded-full size-10 bg-secondary dont-print-this
            transition-all hover:scale-110
            ${isDisabled ? 'opacity-30' : ''} ${className}`}
      onClick={onClick}
    >
      {getIcon(icon.ARROW, orientation)}
    </button>
  );
};

ArrowButton.propTypes = {
  isDisabled: propTypes.bool,
  onClick: propTypes.func,
  className: propTypes.string,
  orientation: propTypes.string,
  tabIndex: propTypes.number,
  ariaLabel: propTypes.string,
  isAriaHidden: propTypes.bool,
};

export default ArrowButton;

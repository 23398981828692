import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useMutateFetchPage from '../hooks/useFetchPage';
import setTitle from '../util/setTitle';
import icon from '../config/icons.js';
import ContactModal from '../components/LocationDetailsPage/ContactModal';
import IconButton from '../components/generic/buttons/IconButton.js';
import MetaTags from '../components/generic/MetaTags.js';
import { htmlToText } from 'html-to-text';
import logo from '../assets/iconBeta.svg';

const GenericPage = () => {
  const { pageSlug } = useParams();
  const { data, mutate } = useMutateFetchPage(pageSlug);
  const [isContactModal, setContactModal] = useState(false);
  const description = htmlToText(data?.body, {
    wordwrap: 130,
    ignoreImage: true,
    ignoreHref: true,
  });
  const meta_description = description.slice(0, 150);
  useEffect(() => {
    mutate(pageSlug);
    window.scrollTo(0, 0);
  }, [pageSlug]);

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      const headers = document.querySelectorAll('.accordion-header');
      headers.forEach((header) => {
        const button = document.createElement('span');
        button.className = 'button';
        button.textContent = 'Se svar +';
        header.appendChild(button);

        header.addEventListener('click', () => {
          const body = header.nextElementSibling;
          body.classList.toggle('accordion-active');
        });
      });
    }
  }, [data]);

  if (!data) {
    return <>Läser in...</>;
  }

  return (
    <main id="main-content">
      <MetaTags
        title={'Tillgänglighetskartan: ' + data?.title}
        description={meta_description}
      />
      <div
        className="
      w-full md:min-h-[calc(80vh-145px)] mx-auto
      sm:mt-[70px]
      bg-secondary
      flex flex-col md:flex-row
      "
      >
        <Link className="sm:hidden bg-accent w-full" to="/">
          <img alt="logotyp" className="w-72 px-md py-md" src={logo} />
        </Link>
        <div className="w-screen h-auto md:ml-20 md:mt-28 flex flex-col md:flex-row items-stretch mx-auto">
          <div className="h-full mb-10 md:mb-12 md:w-[80%] lg:w-1/2 max-w-[760px] flex flex-col">
            <div
              className="genericContent w-full"
              dangerouslySetInnerHTML={{ __html: data?.body }}
            />
            <div className="w-[80%] mx-auto md:mx-0 md:w-40 md:ml-4">
              <IconButton
                buttonStyle={'w-full flex justify-center'}
                isDark={true}
                text="Kontakta oss"
                icon={icon.EMAIL}
                onClick={() => setContactModal(true)}
              />
            </div>
          </div>

          <ContactModal
            onClose={() => setContactModal(false)}
            isOpen={isContactModal}
          />
        </div>
      </div>
    </main>
  );
};

export default GenericPage;

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import useMutateFetchLocations from '../hooks/useMutateFetchLocations.js';
import { locations } from '../state/location.js';
import { headerState } from '../state/filter.js';
import icon from '../config/icons.js';
import PAGE_SIZE from '../config/pageSize.js';

import LocationList from '../components/Location/LocationList.js';
import IconButton from '../components/generic/buttons/IconButton.js';
import MapListView from '../components/Maps/MapListView.js';
import MetaTags from '../components/generic/MetaTags.js';

import splash from '../assets/images/splash.svg';

const LandingPage = () => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const [isEndReached, setIsEndReached] = useState(false);
  const {
    isLoading: isLocationsLoading,
    mutate: mutateFetchLocations,
    data: fetchRes,
  } = useMutateFetchLocations();
  const [allLocations, setLocations] = useRecoilState(locations);
  const isHeader = useRecoilValue(headerState);

  const ids = query.get('ids');
  const page = query.get('page') || 1;
  const pageSize = PAGE_SIZE;
  const locationType = query.get('type');
  const municipality = query.get('municipality');
  const view = query.get('view');
  const isMapView = view === 'map';

  const canFetchMore =
    isEndReached && !isLocationsLoading && fetchRes?.next && !isMapView;

  useEffect(() => {
    const locationIds = ids?.split(',').map((id) => Number(id));
    const isAppend = page > 1;

    if (!isAppend) {
      setLocations([]);
    }

    mutateFetchLocations(
      { page, pageSize, locationType, municipality, locationIds },
      {
        onSuccess: (data) => {
          setLocations((prev) => [...prev, ...data.results]);
        },
      }
    );
  }, [ids, page, locationType]);

  const toggleMapView = () => {
    if (canFetchMore) {
      fetchNextPage();
    } else {
      query.set('view', isMapView ? 'list' : 'map');
      navigate(`${pathname}?${query.toString()}`, { replace: true });
    }
  };

  const fetchNextPage = () => {
    const nextPage = Number(query.get('page') || 1) + 1;
    query.set('page', nextPage);
    navigate(`${pathname}?${query.toString()}`, { replace: true });
  };

  const delParam = (param) => {
    query.delete(param);
    navigate(`${pathname}?${query.toString()}`, { replace: true });
  };

  useEffect(() => {
    query.set('page', 1);
    navigate(`${pathname}?${query.toString()}`, { replace: true });

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      let offset = 350;

      if (scrollTop < offset) {
        offset = 0;
      }

      const isEnd =
        Math.ceil(scrollTop + windowHeight + offset) >=
        Math.floor(documentHeight);

      setIsEndReached(isEnd);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <main
      className="relative pb-32 transition-all duration-header pt-[115px] md:pt-[145px]"
      id="main-content"
    >
      <MetaTags
        title="Tillgänglighetskartan"
        description="Utforska tillgängliga platser med Tillgänglighetskartan karttjänst."
        image={'https://tillganglighetskartan.se/' + splash}
      />
      {isMapView ? (
        <MapListView
          locations={allLocations}
          locationsQueryResult={fetchRes}
          isLoading={isLocationsLoading}
        />
      ) : (
        <LocationList
          allLocations={allLocations}
          isLoading={isLocationsLoading}
        />
      )}
      <IconButton
        buttonStyle={`
        ${isHeader ? 'opacity-0' : 'opacity-100'}
        ${isMapView ? 'fixed' : 'sticky'}
        bottom-6 md:bottom-16 font-medium
        transform -translate-x-1/2 left-1/2 z-[6]
        bg-primary text-secondary py-4 px-6 text-md`}
        text={`${isMapView ? 'Lista' : canFetchMore ? 'Läs in fler' : 'Karta'} `}
        icon={isMapView ? icon.MENU : canFetchMore ? icon.PLUS : icon.MAP}
        iconStyle="text-xl"
        onClick={toggleMapView}
      />
    </main>
  );
};

export default LandingPage;

import React from 'react';
import propTypes from 'prop-types';

const FilterDropdownWrapper = ({
  onClose,
  isOpen,
  extraStyle,
  children,
  id,
}) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  return (
    <div
      onKeyDown={handleKeyDown}
      tabIndex={-1}
      id="filterDropdown"
      className={`absolute
            bg-secondary rounded-2xl
            shadow-md
            overflow-hidden
            transition-all duration-filter 
            ${isOpen ? 'h-[600px] max-h-[80vh] p-4 border opacity-100' : 'opacity-0 h-0'}
            ${extraStyle}`}
      aria-hidden={!isOpen}
      aria-expanded={isOpen}
    >
      {children}
    </div>
  );
};

FilterDropdownWrapper.propTypes = {
  isOpen: propTypes.bool,
  extraStyle: propTypes.string,
  children: propTypes.any,
};

export default FilterDropdownWrapper;

import propTypes from 'prop-types';
import getIcon from '../../../util/getIcon';
import icon from '../../../config/icons';

const FilterSearchButton = ({
  className,
  onClick,
  isMinimized,
  text,
  ariaLabel,
}) => {
  return (
    <button
      className={`flex items-center text-base font-medium
      bg-primary text-secondary text-center justify-center rounded-filter
       transition-all duration-filter hover:opacity-hover h-full w-full 
       ${className}
       ${isMinimized ? 'w-full h-full' : 'space-x-4 lg:h-full lg:w-full p-4'}
       `}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {getIcon(icon.SEARCH)}
      <p
        className={`
        sm:text-sm md:text-base
        whitespace-nowrap overflow-hidden transition-all duration-filter
        ${isMinimized ? 'w-0 opacity-0' : 'opacity-100'}`}
      >
        {text || 'Hitta platser'}
      </p>
    </button>
  );
};

FilterSearchButton.propTypes = {
  onClick: propTypes.func,
  isMinimized: propTypes.bool,
  text: propTypes.string,
  className: propTypes.string,
  ariaLabel: propTypes.string,
};

export default FilterSearchButton;

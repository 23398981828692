/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { format } from 'date-fns';
import { sv } from 'date-fns/locale';

import useMutateFetchLocation from '../hooks/useMutateFetchLocation.js';
import useFetchAccessibilityTags from '../hooks/useFetchAccessibilityTags.js';

import icon from '../config/icons.js';

import setTitle from '../util/setTitle.js';
import getIcon from '../util/getIcon.js';
import disableBackgroundScroll from '../util/disableBackgroundScroll.js';
import useCloseOnOutsideClick from '../util/closeOnOutsideClick.js';

import { accessibilityTags, counties } from '../state/filter.js';

import LocationDetailsPlaceDescription from '../components/LocationDetailsPage/LocationDetailsPlaceDescription.js';
import LocationDetailsTagsList from '../components/LocationDetailsPage/LocationDetailsTagsList.js';
import LocationDetailsUpperSection from '../components/LocationDetailsPage/LocationDetailsUpperSection.js';
import LocationDetailsMap from '../components/LocationDetailsPage/LocationDetailsMap.js';
import IconButton from '../components/generic/buttons/IconButton.js';
import LocationDetailsGallery from '../components/LocationDetailsPage/LocationDetailsGallery/LocationDetailsGallery.js';
import LocationDetailsGalleryModal from '../components/LocationDetailsPage/LocationDetailsGallery/LocationDetailsGalleryModal.js';
import LocationDetailsMapViewSwitcher from '../components/LocationDetailsPage/LocationDetailsMapViewSwitcher.js';
import IconLinkButton from '../components/generic/buttons/IconLinkButton.js';
import LocationDetailsLoader from '../components/LocationDetailsPage/LocationDetailsLoader.js';
import ShareModal from '../components/LocationDetailsPage/ShareModal.js';
import LocationDetailsRichTextMenu from '../components/LocationDetailsPage/LocationDetailsRichTextMenu.js';
import ReportModal from '../components/LocationDetailsPage/ReportModal.js';
import ReviewList from '../components/LocationDetailsPage/reviews/ReviewList.js';
import ReviewSummaryCard from '../components/LocationDetailsPage/reviews/ReviewSummaryCard.js';
import ReviewModal from '../components/LocationDetailsPage/reviews/ReviewModal.js';
import LocationDetails360Modal from '../components/LocationDetailsPage/LocationDetails360Modal.js';
import MetaTags from '../components/generic/MetaTags.js';
import LocationRecommendationList from '../components/LocationDetailsPage/recommendations/LocationRecommendationList.js';
import CloseButton from '../components/generic/buttons/CloseButton.js';

const LocationDetailPage = () => {
  const { id } = useParams();
  const [isMapView, setMapView] = useState(false);
  const [is360View, set360View] = useState(false);
  const [isShareModal, setShareModal] = useState(false);
  const [isReportModal, setReportModal] = useState(false);
  const [isReviewModal, setReviewModal] = useState(false);
  const [isMapFocus, setMapFocus] = useState(false);
  const mapContainerRef = useRef();
  const { isLoading, data: location, mutate } = useMutateFetchLocation();
  const {
    isLoading: isAccessibilityTagsLoading,
    refetch: reFetchAccessibilityTags,
  } = useFetchAccessibilityTags();
  const [galleryModalIndex, setGalleryModalIndex] = useState(-1);
  const allAccessibilityTags = useRecoilValue(accessibilityTags);
  const allCounties = useRecoilValue(counties);
  const municipalityId = allCounties
    .flatMap((county) => county.municipalities)
    ?.find((m) => m.name === location?.municipality)?.id;
  const allTags = allAccessibilityTags.flatMap((cat) => cat.tags);
  const hasReviews = location?.reviews.length > 0;

  const getTags = (ids) => {
    return allTags.filter((tag) => ids.includes(tag.id));
  };

  disableBackgroundScroll(
    isReviewModal ||
      isReportModal ||
      isShareModal ||
      isMapView ||
      is360View ||
      galleryModalIndex !== -1
  );

  useEffect(() => {
    mutate(id);
    reFetchAccessibilityTags();
    setTitle('Tillgänglighetskartan');
    window.scrollTo(0, 0);
  }, [id]);

  const handleMapClick = () => {
    if (!isMapFocus) {
      setMapFocus(true);
    }
  };

  const getLastUpdatedDate = () => {
    return format(new Date(location?.last_updated), 'd MMMM yyyy', {
      locale: sv,
    });
  };

  useCloseOnOutsideClick(mapContainerRef, setMapFocus);

  return (
    <main
      className="relative w-full mt-[10px] md:mt-[110px] mb-16 mx-auto
    md:w-[80vw] max-w-singlePage flex dont-print-margin px-sm md:px-md lg:px-lg"
      id="main-content"
    >
      <MetaTags
        title={
          'Tillgänglighetskartan: ' +
          location?.name +
          ' ' +
          location?.municipality
        }
        description={location?.subheader ? location?.subheader : ''}
        image={location?.image ? location?.image : ''}
      />
      {galleryModalIndex === -1 && (
        <LocationDetailsMapViewSwitcher setMap={setMapView} isMap={isMapView} />
      )}
      <ShareModal
        onClose={() => setShareModal(false)}
        isOpen={isShareModal}
        location={location}
      />
      <ReportModal
        onClose={() => setReportModal(false)}
        isOpen={isReportModal}
        locationId={location?.id}
      />
      <ReviewModal
        onClose={() => setReviewModal(false)}
        isOpen={isReviewModal}
        locationId={location?.id}
      />
      <LocationDetails360Modal
        image={location?.image3D}
        isOpen={is360View}
        setOpen={set360View}
      />
      {isMapView && (
        <div className="fixed size-full z-10 inset-0 overflow-hidden">
          <LocationDetailsMap location={location} isActive={true} />
        </div>
      )}
      {isLoading || isAccessibilityTagsLoading || !location ? (
        <LocationDetailsLoader />
      ) : (
        <>
          <LocationDetailsGalleryModal
            images={location.images}
            onClose={() => setGalleryModalIndex(-1)}
            isOpen={galleryModalIndex !== -1}
            defaultIndex={galleryModalIndex}
          />
          <div className="flex flex-col w-full">
            <LocationDetailsUpperSection
              locationName={location?.name}
              municipality={location?.municipality}
              municipalityId={municipalityId}
              locationTypeLabel={location?.location_type_label}
              locationTypeId={location?.location_type}
              subHeader={location?.subheader}
              locationId={location?.id}
              handleShare={() => setShareModal(true)}
              lastUpdated={getLastUpdatedDate()}
            />
            <section className="flex md:mb-6 h-[300px] sm:h-[600px] relative">
              <div className="w-full md:w-1/2 relative mr-3 mb-3 md:mb-0">
                <LocationDetailsGallery
                  images={location.images}
                  mainImage={location.image}
                  onImageClick={(imageIndex) =>
                    setGalleryModalIndex(imageIndex)
                  }
                />
                {location.images.length > 0 && (
                  <IconButton
                    buttonStyle="invisible md:visible absolute left-1/2 md:left-auto transform -translate-x-1/2 md:translate-x-0 md:right-2 bottom-2 md:bottom-5"
                    text="Visa alla bilder"
                    icon={icon.IMAGE}
                    onClick={() => setGalleryModalIndex(0)}
                    isDark={false}
                  />
                )}
              </div>
              {location.image3D && (
                <IconButton
                  buttonStyle="absolute top-2 left-2 z-[8]"
                  text="Visa i 360"
                  icon={icon._360}
                  onClick={() => set360View(true)}
                  isDark={false}
                />
              )}
              <button
                ref={mapContainerRef}
                onClick={() => handleMapClick()}
                className={`overflow-hidden rounded-2xl h-full w-1/2 hidden md:block relative animate-fadeIn border-2
                  transition-all relative
                  ${isMapFocus ? 'border-primary shadow-lg lg:scale-[1.20]' : 'border-transparent hover:border-border'}`}
              >
                <LocationDetailsMap location={location} isActive={isMapFocus} />
                {isMapFocus && (
                  <CloseButton
                    onClick={() => setMapFocus(false)}
                    buttonStyle="absolute top-4 left-4 text-2xl"
                  />
                )}
              </button>
            </section>
            <section className="flex justify-between mb-6">
              <div className="flex flex-col gap-2">
                <h3 className="text-3xl font-medium text-text1">
                  {location.name}
                </h3>
                <h4 className="text-sm text-text2 md:hidden">
                  Uppdaterad: {getLastUpdatedDate()}
                </h4>
                <div className="flex items-center gap-2 text-text2">
                  {getIcon(icon.PIN)}
                  <p className="text-sm">{location.address} 123</p>
                </div>
              </div>
              <IconLinkButton
                text="Hitta hit"
                buttonStyle="md:flex px-4"
                icon={icon.DIRECTIONS}
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(location.name + ', ' + location.address)}`}
                isDark={false}
                target="_blank"
              />
            </section>
            <section className="flex flex-col md:flex-row gap-y-6 md:gap-y-0 gap-x-20 mt-6 ">
              <div className="w-full md:w-1/2 h-full space-y-10">
                <LocationDetailsPlaceDescription
                  title="Beskrivning"
                  description={location.description}
                />
                {location.rich_descriptions?.length > 0 && (
                  <LocationDetailsRichTextMenu
                    richTextItems={location.rich_descriptions}
                  />
                )}
              </div>
              {location.accessibility_tags.length > 0 && (
                <div className="w-full md:w-1/2 space-y-20 md:space-y-8 mt-8 md:mt-0 dont-print-break">
                  <LocationDetailsTagsList
                    accessibilityTags={getTags(location.accessibility_tags)}
                  />
                </div>
              )}
            </section>
            <div
              className={`${hasReviews ? 'border border-border' : 'mt-6'} my-20`}
            />
            <section>
              {location.reviews.length > 0 && (
                <div className="flex flex justify-between">
                  <h3 className="text-2xl font-medium mb-6">Omdömen</h3>
                </div>
              )}
              <div className="flex flex-col-reverse md:flex-row md:space-x-16 relative">
                {location.reviews.length > 0 && (
                  <div className="w-full md:w-1/2 space-y-8">
                    <ReviewList reviews={location.reviews} />
                  </div>
                )}
                <div
                  className={`${location.reviews.length > 0 ? 'w-1/2' : 'w-full'} flex flex-col w-full m:w-1/2 space-y-8 mb-8 md:mb-0 md:mt-0 md:sticky md:top-56 h-fit`}
                >
                  {location.reviews.length > 0 && (
                    <ReviewSummaryCard
                      score={location.average_rating}
                      reviews={location.reviews}
                    />
                  )}
                  <div className="flex w-full justify-between items-center flex-col md:flex-row space-y-10 md:space-y-0 md:space-x-2 dont-print-this">
                    <div className="w-full md:w-fit md:space-x-4 border border-border space-y-4 md:space-y-0 rounded-xl flex-col md:flex-row md:items-center flex p-5 justify-between items-center">
                      <p className="text-md text-text1">Har du varit här?</p>
                      <IconButton
                        text="Lämna ett omdöme"
                        onClick={() => setReviewModal(true)}
                      />
                    </div>
                    {location.report_link ? (
                      <Link
                        className="items-center space-x-2 underline hidden md:flex"
                        target="_blank"
                        to={location.report_link}
                      >
                        {getIcon(icon.FLAG)}
                        <p className="whitespace-nowrap">
                          Rapportera denna platsen
                        </p>
                      </Link>
                    ) : (
                      <button
                        className="items-center space-x-2 underline hidden md:flex"
                        onClick={() => setReportModal(true)}
                      >
                        {getIcon(icon.FLAG)}
                        <p className="whitespace-nowrap">
                          Rapportera denna platsen
                        </p>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </section>
            <section className="flex justify-center md:hidden mt-10 dont-print-this">
              {location.report_link ? (
                <Link
                  className="items-center flex space-x-2 underline w-fit"
                  target="_blank"
                  to={location.report_link}
                >
                  {getIcon(icon.FLAG)}
                  <p className="whitespace-nowrap">Rapportera denna platsen</p>
                </Link>
              ) : (
                <button
                  className="items-center flex space-x-2 underline w-fit"
                  onClick={() => setReportModal(true)}
                >
                  {getIcon(icon.FLAG)}
                  <p className="whitespace-nowrap">Rapportera denna platsen</p>
                </button>
              )}
            </section>
            <div className="border border-border my-20 dont-print-this" />
            <section>
              <LocationRecommendationList locationId={location.id} />
            </section>
          </div>
        </>
      )}
    </main>
  );
};

export default LocationDetailPage;

import React from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import icon from '../../../config/icons';
import getIcon from '../../../util/getIcon';

const FooterSection = ({ title, items, isExternal }) => {
  return (
    <section>
      <h2 className="text-2xl mb-5 font-medium">{title}</h2>
      <ul className="text-base">
        {items?.map((el, idx) => (
          <li key={idx} className="flex group">
            <Link
              target={isExternal ? '_blank' : '_self'}
              className="hover:underline"
              to={el.slug}
            >
              {el.title}
            </Link>
            {isExternal &&
              getIcon(
                icon.ARROW_OUTWARD,
                `h-6 w-6 fill-transparent ml-2 items-center justify-center p-1 group-hover:fill-primary group-hover:bg-accent group-hover:rounded-md`
              )}
          </li>
        ))}
      </ul>
    </section>
  );
};

FooterSection.propTypes = {
  title: propTypes.string,
  isExternal: propTypes.bool,
  items: propTypes.array,
};

export default FooterSection;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../generic/buttons/IconButton';
import ModalCard from '../../generic/ModalCard';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import actions from '../../../api/reviews';

const ReviewModal = ({ isOpen, onClose, locationId }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [rating, setRating] = useState(0);
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [confirmation, setConfirmation] = useState('');

  const handleRating = (rate) => {
    setRating(rate);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('sender_email', email);
    formData.append('sender_name', name);
    formData.append('rating', rating);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('location', locationId);
    if (image) {
      formData.append('image', image);
    }

    try {
      await actions.postReview(formData);
      setConfirmation(
        'Tack för din recension! Din feedback har skickats in till oss. Vi uppskattar att du har tagit dig tid att dela din upplevelse med oss.'
      );
      setEmail('');
      setName('');
      setRating(0);
      setDescription('');
      setTitle('');
      setImage(null);
      setConfirmation('');
      onClose();
    } catch (error) {
      setConfirmation(
        'Något gick fel... Vi kunde inte skicka din recension. Vänligen kontrollera att alla obligatoriska fält är ifyllda och försök igen. Om problemet kvarstår, vänligen kontakta vår support.'
      );
    }
  };

  return (
    <ModalCard
      onClose={onClose}
      isOpen={isOpen}
      size="size-full md:max-h-[600px] md:max-w-[700px]"
      padding="p-0"
    >
      <form onSubmit={handleSubmit} className="size-full flex flex-col">
        <div className="text-center w-full border-b border-border p-6 ">
          <h2 className="text-2xl font-medium">Lämna ett omdöme</h2>
        </div>
        <div className="review-form px-5 bg-secondary overflow-y-auto">
          <div className="flex w-full flex-col md:flex-row md:space-x-6">
            <div className="mb-4 md:w-1/2">
              <label className="block text-sm font-medium mb-1">
                E-postadress *
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="border p-2 rounded-md w-full"
                />
              </label>
            </div>
            <div className="mb-4 md:w-1/2">
              <label className="block text-sm font-medium mb-1">
                Namn *
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="border p-2 rounded-md w-full"
                />
              </label>
            </div>
          </div>
          <div className="mb-4 md:w-1/2">
            <p className="block text-sm font-medium mb-1">Betyg *</p>
            <div className="flex" aria-label="Sätt betyg">
              {[1, 2, 3, 4, 5].map((star) => (
                <button
                  key={star}
                  type="button"
                  onClick={() => handleRating(star)}
                  className="text-xl"
                  aria-label={`${star} ${star > 1 ? 'stjärnor' : 'stjärna'}`}
                >
                  {rating >= star ? <AiFillStar /> : <AiOutlineStar />}
                </button>
              ))}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full md:space-x-6">
            <div className="mb-4 md:w-1/2">
              <label className="block text-sm font-medium mb-1">
                Titel
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="border p-2 rounded-md w-full"
                />
              </label>
            </div>
            <div className="mb-4 md:w-1/2">
              <label className="block text-sm font-medium mb-1">
                Bild
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="border p-2 rounded-md w-full"
                />
              </label>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Beskrivning
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="border p-2 rounded-md w-full"
              />
            </label>
          </div>
          {confirmation && (
            <p className="mt-4 text-green-600">{confirmation}</p>
          )}
        </div>
        <div className="w-full border-t border-border flex mt-auto  py-20 md:py-10">
          <IconButton
            buttonStyle="my-auto h-12 w-5/6 mx-auto md:mr-8 md:w-36"
            text="Skicka"
            onClick={handleSubmit}
          />
        </div>
      </form>
    </ModalCard>
  );
};

ReviewModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ReviewModal;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

const LocationDetailsRichTextMenu = ({ richTextItems }) => {
  const [currentItem, setCurrentItem] = useState(0);

  return (
    <div className="h-auto">
      <h3 className="text-2xl font-medium mb-6">Information</h3>
      <div>
        <ol className="flex space-x-4 mb-6 overflow-auto">
          {richTextItems.map((item, idx) => (
            <li>
              <button
                key={idx}
                onClick={() => setCurrentItem(idx)}
                className={`rounded-full px-4 py-1 hover:border-primary
                    transition-all whitespace-nowrap border border-transparent
                    ${currentItem === idx ? 'bg-primary text-secondary' : 'bg-secondary text-primary'}`}
              >
                {item.title}
              </button>
            </li>
          ))}
        </ol>
        <p
          className="text-text2 max-h-96 overflow-y-auto"
          dangerouslySetInnerHTML={{
            __html: richTextItems[currentItem].description,
          }}
        />
      </div>
    </div>
  );
};

LocationDetailsRichTextMenu.propTypes = {
  richTextItems: PropTypes.array,
};

export default LocationDetailsRichTextMenu;

import React from 'react';
import PropTypes from 'prop-types';
import truncateText from '../../../util/truncateText';
import IconButton from '../../generic/buttons/IconButton';
import IconLinkButton from '../../generic/buttons/IconLinkButton';
import icon from '../../../config/icons';
import LocationTag from '../../Location/LocationTag';
import LocationGallerySwiper from '../../generic/LocationGallerySwiper';
import { getLocationLink } from '../../../util/getLocationLink';

const MapSingleLocation = ({
  location,
  accessibilityTags,
  locationTypeLabelSingular,
  municipality,
  locationTypeLabel,
  locationIcon,
  onClose,
  is360View,
}) => {
  return (
    <div
      className={`${is360View ? 'text-primary' : 'text-primary/80'} size-full pb-4`}
    >
      <div className="flex justify-between items-center mb-4">
        <IconButton
          text="Tillbaka"
          onClick={onClose}
          buttonStyle={'px-4'}
          icon={icon.ARROW}
          iconStyle="-rotate-90"
        />
      </div>
      <div className="max-h-[85%] overflow-auto pb-4 z-50 bg-secondary">
        <div className="w-full h-64 xl:h-72">
          <LocationGallerySwiper images={location.images} />
        </div>
        <div className="w-full flex py-2 px-1 items-center space-x-4">
          <h2 className="font-semibold text-xl">{location.name}</h2>
          <div className="flex space-x-1">
            <div>{locationIcon}</div>
            <p className="mt-1">{locationTypeLabelSingular}</p>
          </div>
        </div>
        <div className="space-y-4">
          <div>
            <p className="text-md pb-4 leading-6 text-text3">
              {truncateText(location.description, 250)}
            </p>
          </div>
          {accessibilityTags && (
            <div>
              <h4 className="text-sm text-text1 mb-4">
                Denna plats är anpassad för:
              </h4>
              <div className="flex w-full flex flex-wrap">
                {accessibilityTags?.map((t, idx) => (
                  <LocationTag
                    key={idx}
                    name={t.name}
                    label={t.label}
                    image={t.image}
                    className="mr-1 mb-1"
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <IconLinkButton
        text="Visa mer"
        buttonStyle="flex-row-reverse space-x-reverse w-full justify-center h-12 absolute bottom-0"
        iconStyle="rotate-90"
        icon={icon.ARROW}
        href={getLocationLink(
          municipality,
          locationTypeLabel,
          location.name,
          location.id
        )}
      />
    </div>
  );
};

MapSingleLocation.propTypes = {
  location: PropTypes.object,
  municipality: PropTypes.string,
  locationTypeLabel: PropTypes.string,
  locationTypeLabelSingular: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  is360View: PropTypes.bool.isRequired,
  locationIcon: PropTypes.any.isRequired,
  accessibilityTags: PropTypes.array.isRequired,
};

export default MapSingleLocation;

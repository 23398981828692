import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import propTypes from 'prop-types';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import useMutateFetchOptions from '../../../../../hooks/useMutateFetchOptions';
import {
  accessibilityTags,
  counties,
  locationTree,
  locationTypes,
  options,
  selectedAccessibilityTypes,
  selectedLocationTypes,
} from '../../../../../state/filter';
import generateFilterText from '../../../../../util/generateFilterText';
import closeOnOutsideClickFunc from '../../../../../util/closeOnOutsideClickFunc';
import icon from '../../../../../config/icons';
import FilterItem from './FilterItem';
import FilterSearchButton from '../../../buttons/FilterSearchButton';
import FilterDropdownWrapper from '../dropdown/FilterDropdownWrapper';
import RegionPicker from '../dropdown/RegionPicker';
import AccessibilityPicker from '../dropdown/AccessibilityPicker';
import LocationTypePicker from '../dropdown/LocationTypePicker';
import Loader from '../../../loading/Loader';
import LocationTag from '../../../../Location/LocationTag';
import IconButton from '../../../buttons/IconButton';

const MainFilter = ({
  isMinimized,
  active,
  setActive,
  isHeader,
  setLocationIds,
  clearFilter,
  isFiltered,
  onFilterFocus,
}) => {
  const [outputOptions, setOutputOptions] = useRecoilState(options);
  const [inputOptions, setInputOptions] = useState({});
  const filterRef = useRef(null);
  const {
    mutate: mutateFetchOptions,
    isSuccess: isOptionsSuccess,
    data: optionsData,
    isLoading,
  } = useMutateFetchOptions(inputOptions);

  const allCounties = useRecoilValue(counties);
  const allLocationTypes = useRecoilValue(locationTypes);
  const allAccessibilityTags = useRecoilValue(accessibilityTags);

  const resetCounties = useResetRecoilState(locationTree);
  const resetAccessibilityTypes = useResetRecoilState(
    selectedAccessibilityTypes
  );
  const resetLocationTypes = useResetRecoilState(selectedLocationTypes);

  const onClose = () => {
    setActive(-1);
  };

  closeOnOutsideClickFunc(filterRef, onClose);

  const handleClearFilter = () => {
    resetCounties();
    resetAccessibilityTypes();
    resetLocationTypes();
    setInputOptions({});
    clearFilter();
  };

  const onSearch = useCallback(() => {
    setLocationIds(outputOptions.location_ids);
    setActive(-1);
  }, [setLocationIds, outputOptions.location_ids, setActive]);

  const onSubmit = useCallback(
    (key, data) => {
      if (data !== null) {
        setActive((prevActive) =>
          prevActive === items.length ? -1 : prevActive + 1
        );
      }

      setInputOptions((prevState) => {
        const newState = { ...prevState };
        if (!data || data.length === 0) {
          delete newState[key];
        } else {
          newState[key] = data;
        }
        return newState;
      });
    },
    [setActive]
  );

  const handleSetActive = (id) => {
    if (id === -1) {
      const filterItem = window.document.getElementById('filterItem' + active);
      filterItem?.focus();
    } else {
      window.document.getElementById('filterDropdown').focus();
    }
    setActive(active === id ? -1 : id);
  };

  const getRegionText = () =>
    generateFilterText(
      allCounties.flatMap((county) => county.municipalities),
      inputOptions.municipality_ids,
      'Hela Sverige'
    );

  const getLocationTypeText = () =>
    generateFilterText(
      allLocationTypes,
      inputOptions.location_type_ids,
      'Vad vill du söka efter?'
    );

  const getAccessibilityText = () =>
    generateFilterText(
      allAccessibilityTags.flatMap((parent) => parent.tags),
      inputOptions.accessibility_tag_ids,
      'Välj utifrån tillgänglighet'
    );

  useEffect(() => {
    mutateFetchOptions();
  }, [inputOptions, mutateFetchOptions]);

  useEffect(() => {
    if (isOptionsSuccess) {
      setOutputOptions(optionsData);
    }
  }, [isOptionsSuccess, optionsData, setOutputOptions]);

  useEffect(() => {
    if (active === -1 && Object.keys(inputOptions).length > 0) {
      onSearch();
    }
  }, [outputOptions]);

  useEffect(() => {
    if (!isHeader) {
      setActive(-1);
    }
  }, [isHeader, setActive]);

  const getAccessibilityIcons = () => {
    const MAX_TAGS = 4;
    const filteredTags = allAccessibilityTags
      .flatMap((parent) => parent.tags)
      .filter((tag) => inputOptions.accessibility_tag_ids?.includes(tag.id));

    const hasMoreTags = filteredTags.length > MAX_TAGS;
    const tagsToShow = hasMoreTags
      ? filteredTags.slice(0, MAX_TAGS - 1)
      : filteredTags;

    return (
      <div className="flex space-x-2">
        {tagsToShow.map((tag) => (
          <LocationTag
            key={tag.id}
            name={tag.name}
            className="bg-transparent"
            ariaLabel={tag.label}
          />
        ))}
        {hasMoreTags && (
          <LocationTag
            label={`+${filteredTags.length - (MAX_TAGS - 1)}`}
            className="bg-transparent"
            ariaLabel={`${filteredTags.length - (MAX_TAGS - 1)} fler taggar`}
          />
        )}
      </div>
    );
  };

  const items = useMemo(
    () => [
      {
        id: 1,
        title: 'Välj län',
        subTitle: getRegionText(),
        minimizedValue:
          inputOptions.municipality_ids?.length > 0
            ? getRegionText()
            : 'Region',
        dropdown: (
          <RegionPicker
            availableOptions={outputOptions}
            onSubmit={(val) => onSubmit('municipality_ids', val)}
          />
        ),
      },
      {
        id: 2,
        title: 'Välj typ av plats',
        subTitle: getLocationTypeText(),
        minimizedValue:
          inputOptions.location_type_ids?.length > 0
            ? getLocationTypeText()
            : 'Plats',
        dropdown: (
          <LocationTypePicker
            availableOptions={outputOptions.location_types}
            onSubmit={(val) => onSubmit('location_type_ids', val)}
          />
        ),
      },
      {
        id: 3,
        title: 'Tillgänglighet',
        subTitle:
          inputOptions.accessibility_tag_ids?.length > 0
            ? getAccessibilityIcons()
            : 'Välj kriterier',
        minimizedValue:
          inputOptions.accessibility_tag_ids?.length > 0
            ? getAccessibilityIcons()
            : 'Tillgänglighet',
        dropdown: (
          <AccessibilityPicker
            availableOptions={outputOptions.accessibility_tags}
            onSubmit={(val) => onSubmit('accessibility_tag_ids', val)}
          />
        ),
      },
    ],
    [
      outputOptions,
      getRegionText,
      getLocationTypeText,
      getAccessibilityText,
      onSubmit,
    ]
  );

  return (
    <>
      <div
        className={`
        relative border border-border rounded-filter shadow
        transition-all duration-header ease-filter p-1
        ${isMinimized ? 'h-auto w-auto' : 'h-fit'}
        ${active !== -1 ? ' bg-hover' : 'bg-secondary'}
      `}
        ref={filterRef}
      >
        {isLoading && (
          <div className="absolute -left-10 transform -translate-y-1/2 top-1/2">
            <Loader />
          </div>
        )}
        <ul
          className="flex justify-between w-full h-fit rounded-full relative"
          aria-label="Sökfilter"
        >
          {items.map((item, idx) => (
            <li className="flex w-auto" key={item.id} onClick={onFilterFocus}>
              <FilterItem
                id={item.id}
                title={item.title}
                subTitle={item.subTitle}
                minimizedValue={item.minimizedValue}
                setActive={() => handleSetActive(item.id)}
                activeItem={active}
                isMinimized={isMinimized}
                ariaLabel={item.title}
              />
              <div
                className={`transition-all border h-2/5 group-hover:border-none my-auto mx-1 ${active !== -1 ? 'border-border' : 'border-transparent'}`}
              />
            </li>
          ))}
          <FilterDropdownWrapper
            onClose={() => handleSetActive(-1)}
            isOpen={active !== -1}
            extraStyle={`
              top-full mt-4 w-3/4
                ${active === 1 ? 'left-0' : active === 2 ? 'left-1/2 transform -translate-x-1/2' : active === 3 ? 'right-0' : 'transition-none'}
            `}
          >
            {items.find((i, idx) => idx + 1 === active)?.dropdown}
          </FilterDropdownWrapper>
          <div className={`${isMinimized ? 'w-28' : 'pl-1 min-w-28'}`}>
            <FilterSearchButton
              className="h-fit"
              isMinimized={isMinimized}
              onClick={onSearch}
              text={`Visa ${outputOptions.location_ids.length} platser`}
              ariaLabel="Sök"
            />
          </div>
        </ul>
      </div>
      {isFiltered && (
        <IconButton
          text="Rensa filter"
          buttonStyle="ml-6"
          onClick={handleClearFilter}
          icon={icon.CLOSE}
          isDark={false}
        />
      )}
    </>
  );
};

MainFilter.propTypes = {
  isMinimized: propTypes.bool,
  active: propTypes.number.isRequired,
  setActive: propTypes.func.isRequired,
  isHeader: propTypes.bool.isRequired,
  setLocationIds: propTypes.func.isRequired,
  onFilterFocus: propTypes.func.isRequired,
};

export default MainFilter;

import React from 'react';
import PropTypes from 'prop-types';
import getIcon from '../../util/getIcon';
import icon from '../../config/icons';

const Rating = ({ rating, totalReviews }) => {
  if (!totalReviews) {
    return null;
  }

  return (
    <div className="rating flex items-center justify-center bg-accent/80 px-1.5 py-1 rounded-full text-xs">
      {getIcon(icon.STAR, 'mb-0.5 mr-0.5')}
      <p className="font-medium text-text1 flex space-x-1">
        <span>{rating}</span>
        {<span className="text-text2 font-normal">{` (${totalReviews})`}</span>}
      </p>
    </div>
  );
};

Rating.propTypes = {
  rating: PropTypes.number.isRequired,
  totalReviews: PropTypes.number,
};

export default Rating;

import React from 'react';
import PropTypes from 'prop-types';

const MapBarButton = ({ onClick, style, icon, iconRotation, ariaLabel }) => {
  return (
    <button
      aria-label={ariaLabel}
      onClick={onClick}
      className={`
            bg-secondary hover:bg-primary hover:text-secondary
            transition-all
            hover:bg-hover
            p-2 text-xl ${style}`}
    >
      <div
        style={{
          transform: `rotate(${iconRotation}deg)`,
        }}
      >
        {icon}
      </div>
    </button>
  );
};

MapBarButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  style: PropTypes.string,
  icon: PropTypes.any,
  iconRotation: PropTypes.number,
};

export default MapBarButton;

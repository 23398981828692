import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs, Keyboard } from 'swiper/modules';
import ArrowButton from '../../generic/buttons/ArrowButton';
import ModalCard from '../../generic/ModalCard';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/keyboard';

const LocationDetailsGalleryModal = ({
  images,
  onClose,
  isOpen,
  defaultIndex = 0,
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const onSlide = (data) => {
    setSlideIndex(data.activeIndex);
  };

  return (
    <ModalCard
      onClose={onClose}
      isOpen={isOpen}
      size="size-full md:rounded-none"
      padding="md:0"
    >
      <ArrowButton
        className="custom-prev-gallery absolute left-14 top-1/2 -translate-y-1/2 transform hidden md:flex z-10 shadow-none border border-border"
        orientation="-rotate-90"
        ariaLabel="Föregående bild"
        isDisabled={slideIndex === 0}
      />
      <ArrowButton
        className="custom-next-gallery absolute right-14 top-1/2 -translate-y-1/2 transform hidden md:flex z-10 shadow-none border border-border"
        orientation="rotate-90"
        ariaLabel="Nästa bild"
        isDisabled={slideIndex === images.length - 1}
      />
      <div className="flex flex-col w-full relative h-full max-w-[1920px] mx-auto py-20">
        <Swiper
          onSlideChange={onSlide}
          style={{
            '--swiper-navigation-color': '#000',
            '--swiper-pagination-color': '#000',
          }}
          navigation={{
            nextEl: '.custom-next-gallery',
            prevEl: '.custom-prev-gallery',
          }}
          loop={false}
          spaceBetween={10}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs, Keyboard]}
          keyboard={{ enabled: true }}
          className="mySwiper2 size-full"
        >
          {images.map((image, idx) => (
            <SwiperSlide key={idx} className="overflow-hidden">
              <img
                src={image.image}
                alt={image.description}
                className="size-full object-contain pointer-events-none md:rounded-md"
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="w-full flex justify-end">
          <p className="text-md font-semibold">{`${slideIndex + 1}/${images.length}`}</p>
        </div>
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={false}
          freeMode={true}
          slidesPerView={4}
          spaceBetween={4}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper w-full h-fit"
          breakpoints={{
            640: {
              spaceBetween: 4,
              slidesPerView: 6,
            },
            920: {
              spaceBetween: 8,
              slidesPerView: 8,
            },
            1280: {
              spaceBetween: 8,
              slidesPerView: 8,
            },
            1920: {
              spaceBetween: 8,
              slidesPerView: 10,
            },
          }}
        >
          {images.map((image, idx) => (
            <SwiperSlide
              key={idx}
              className={`border-2 ${slideIndex === idx ? 'border-primary' : 'border-transparent'} overflow-hidden rounded-md`}
            >
              <img
                src={image.image}
                alt={image.description}
                className="w-full object-cover h-32"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </ModalCard>
  );
};

LocationDetailsGalleryModal.propTypes = {
  images: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  defaultIndex: PropTypes.number,
};

export default LocationDetailsGalleryModal;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../generic/buttons/IconButton';
import ModalCard from '../generic/ModalCard';
import actions from '../../api/contacts';
import { isEmailValid } from '../../util/emailValidation';

const ContactModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [descriptionText, setDescriptionText] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const MIN_DESCRIPTION_TEXT = 24;

  const handleReset = () => {
    setEmail('');
    setName('');
    setDescriptionText('');
  };

  const handleClose = () => {
    handleReset();
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('sender_email', email);
    formData.append('sender_name', name);
    formData.append('description', descriptionText);

    try {
      await actions.postContact(formData);
      setConfirmation(
        'Tack för din kontakt! Din feedback har skickats in till oss. Vi uppskattar att du har tagit dig tid att dela din upplevelse med oss.'
      );
      handleReset();
      onClose();
    } catch (error) {
      setConfirmation(
        'Något gick fel... Vi kunde inte skicka din kontakt. Vänligen kontrollera att alla obligatoriska fält är ifyllda och försök igen. Om problemet kvarstår, vänligen kontakta vår support.'
      );
    }
  };

  return (
    <ModalCard
      onClose={handleClose}
      isOpen={isOpen}
      size="size-full md:max-h-[600px] md:max-w-[700px]"
      padding="p-0"
    >
      <form onSubmit={handleSubmit} className="size-full flex flex-col">
        <div className="text-center w-full border-b border-border p-6 mb-8 md:mb-4">
          <h2 className="text-2xl font-medium">Kontakta oss</h2>
        </div>
        <div className="px-8 overflow-y-scroll">
          <div className="flex flex-col my-10 md:m-0">
            <h3 className="text-xl md:text-2xl font-medium">
              Beskriv ditt ärende
            </h3>
            <p className="text-sm md:text-md text-text2 mb-4">
              Dina svar delas med oss och eventuellt ansvariga för kontakt.
            </p>
            <div className="w-full space-y-4">
              <div className="flex space-x-4">
                <div className="flex-1">
                  <label className="block text-sm font-medium mb-1">
                    E-postadress
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      className="border p-2 rounded-md w-full"
                    />
                  </label>
                </div>
                <div className="flex-1">
                  <label className="block text-sm font-medium mb-1">
                    Namn
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                      className="border p-2 rounded-md w-full"
                    />
                  </label>
                </div>
              </div>
              <textarea
                className="border border-border rounded-xl size-full p-2 outline-none h-48"
                placeholder="Beskriv ditt ärende..."
                aria-label={`Beskriv ditt ärende med minst ${MIN_DESCRIPTION_TEXT} bokstäver.`}
                value={descriptionText}
                onChange={(e) => setDescriptionText(e.target.value)}
              />
            </div>
            {confirmation && (
              <p className="mt-4 text-green-600">{confirmation}</p>
            )}
          </div>
          <div className="w-full border-t border-border flex mt-auto py-20 md:py-10">
            <IconButton
              buttonStyle="my-auto h-12 w-5/6 mx-auto md:mr-8 md:w-36"
              text="Skicka"
              isDisabled={
                descriptionText.length < MIN_DESCRIPTION_TEXT ||
                name.trim() === '' ||
                !isEmailValid(email)
              }
              onClick={handleSubmit}
            />
          </div>
        </div>
      </form>
    </ModalCard>
  );
};

ContactModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ContactModal;

import React from 'react';
import PropTypes from 'prop-types';
import icon from '../../config/icons';
import IconButton from '../generic/buttons/IconButton';
import IconLinkButton from '../generic/buttons/IconLinkButton';
import getIcon from '../../util/getIcon';
import MapBarButton from './MapBarButton';

const MapBar = ({
  focusLocation,
  locationData,
  is360View,
  toggle360View,
  handleZoom,
  resetNavigation,
  bearing,
  isViewChanged,
}) => {
  return (
    <div className="absolute z-[6] top-6 md:top-10 right-6 md:right-16 flex flex-col items-end">
      {focusLocation && locationData && (
        <div className="pb-4 md:pb-10 space-y-2 flex flex-col items-end">
          {locationData?.image3D && (
            <IconButton
              isDark={false}
              text={is360View ? 'Visa karta' : 'Visa i 360'}
              iconStyle="text-2xl"
              buttonStyle={`py-2 px-4 text-md hidden md:flex`}
              icon={is360View ? icon.MAP : icon._360}
              onClick={toggle360View}
            />
          )}
          <IconLinkButton
            text="Hitta hit"
            isDark={false}
            iconStyle="text-2xl"
            buttonStyle="py-2 px-4 text-md hidden md:flex"
            icon={icon.DIRECTIONS}
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(locationData.name + ', ' + locationData.address)}`}
            target="_blank"
          />
        </div>
      )}
      <div
        className="hidden md:flex flex-col rounded-lg 
      bg-secondary divide-y-2 overflow-hidden shadow-lg"
      >
        <MapBarButton
          onClick={() => handleZoom(true)}
          icon={getIcon(icon.PLUS)}
          ariaLabel="Zooma in"
        />
        <MapBarButton
          onClick={() => handleZoom(false)}
          icon={getIcon(icon.MINUS)}
          ariaLabel="Zooma ut"
        />
        <MapBarButton
          onClick={resetNavigation}
          ariaLabel="Återställ position"
          icon={
            isViewChanged
              ? getIcon(icon.NAVIGATION_FILLED)
              : getIcon(icon.NAVIGATION)
          }
          iconRotation={bearing}
        />
      </div>
    </div>
  );
};

MapBar.propTypes = {
  focusLocation: PropTypes.any,
  locationData: PropTypes.any,
  is360View: PropTypes.bool,
  toggle360View: PropTypes.func,
  handleZoom: PropTypes.func,
};

export default MapBar;

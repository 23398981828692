import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { isEasyMode } from '../../../../state/view';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../../../util/localStorageUtil';

const UserWayToggle = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const [isWidgetActive, setWidgetActive] = useRecoilState(isEasyMode);
  const UserWay = window.UserWay;
  const STORAGE_KEY = 'EASY_MODE';

  const toggleUserWayWidget = () => {
    setWidgetActive((prev) => {
      const isActive = !prev;
      setActiveUserWay(isActive);
      setLocalStorageItem(STORAGE_KEY, isActive);
      return isActive;
    });
  };

  const setActiveUserWay = useCallback((active) => {
    if (active) {
      query.set('view', 'list');
      navigate(`${pathname}?${query.toString()}`, { replace: true });
      UserWay?.bigCursorEnable();
      UserWay?.bigTextEnable(1);
      UserWay?.legibleFontsEnable();
      UserWay?.tooltipsEnable();
    } else {
      UserWay?.bigCursorDisable();
      UserWay?.bigTextDisable();
      UserWay?.legibleFontsDisable();
      UserWay?.tooltipsDisable();
    }
  });

  useEffect(() => {
    const isEasyMode = getLocalStorageItem(STORAGE_KEY) || false;
    setWidgetActive(isEasyMode);
    setActiveUserWay(isEasyMode);
    const userway = document.querySelector('div.uai');
    userway?.classList.add('dont-print-this');
  }, []);

  return (
    <button
      className="float-left flex items-left space-x-4"
      onClick={toggleUserWayWidget}
      aria-label={`Förenklat läge, ${isWidgetActive ? 'På' : 'Av'}`}
    >
      <p className="text-text1 font-medium text-sm whitespace-nowrap">
        Förenklat läge
      </p>
      <div className="relative inline-block w-12 h-6">
        <input
          type="checkbox"
          className="hidden"
          checked={!!isWidgetActive}
          readOnly
        />
        <div
          className={`block w-full h-full rounded-full transition-all duration-300 ${
            isWidgetActive ? 'bg-black' : 'bg-gray-300'
          }`}
        ></div>
        <div
          className={`absolute left-0 top-0 w-6 h-6 bg-white border-2 rounded-full transition-all duration-300 transform ${
            isWidgetActive ? 'translate-x-6 border-black' : 'border-gray-300'
          }`}
        ></div>
      </div>
    </button>
  );
};

export default UserWayToggle;

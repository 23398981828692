import React from 'react';
import PropTypes from 'prop-types';

const RegionPickerAll = ({ totalCount, isChecked, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`flex w-full h-20 justify-between items-center py-2 px-4 rounded-lg 
        ${isChecked ? 'bg-accent' : ''}`}
      role="checkbox"
      aria-checked={isChecked}
    >
      <div className="flex flex-col items-start">
        <div className="text-xl flex">
          <p className="text-primary mr-1">Hela Sverige</p>
          <p className="text-text4">{`(${totalCount})`}</p>
        </div>
        <div>
          <p className="text-text4">Visar alla platser i Sverige</p>
        </div>
      </div>
      <div>
        <input
          type="checkbox"
          className="accent-primary size-5"
          readOnly={true}
          checked={isChecked}
          tabIndex="-1"
          aria-hidden="true"
        />
      </div>
    </button>
  );
};

RegionPickerAll.propTypes = {
  totalCount: PropTypes.number.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RegionPickerAll;
